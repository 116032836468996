<script lang="ts">
    import type { Feature } from "$docs/types"

    import { chevron_right } from "@timephy/tui-icons-svelte"
    import { Icon } from "svelte-awesome"

    /* ========================================================================================== */

    let className = ""
    export { className as class }

    /* ========================================================================================== */

    export let featureId: string
    export let feature: Feature

    let href = `/feature/${featureId}`
</script>

<a {href} aria-label={feature.article.title}>
    <div class="card flex flex-col overflow-hidden {className}">
        <img
            src={feature.seo.cover_image}
            title={feature.article.title}
            alt="{feature.article.title} Cover"
            class="aspect-[4/3] w-full border-b border-step-050"
        />
        <div class="flex grow flex-col justify-between gap-3 px-6 py-5">
            <div class="flex flex-col gap-1.5">
                <h3 class="line-clamp-1 text-xl font-medium">{feature.article.title}</h3>
                <div class="line-clamp-2 text-sm text-step-500">
                    {feature.article.first_section}
                </div>
            </div>
            <div
                class="flex items-center gap-1.5 text-blue-000 hover:text-blue-100 active:text-blue-200"
            >
                <p class="line-clamp-1 text-sm">{feature.meta.see_more}</p>
                <Icon data={chevron_right} class="h-4 w-4 shrink-0" />
            </div>
        </div>
    </div>
</a>
